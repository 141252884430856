#Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-color-1);
  position: relative;

  .info {
    position: absolute;
    z-index: 2;
    p {
      color: var(--font-color-1);
      font-size: clamp(2rem, 7vw, 6rem);
      margin: 0;
      font-family: var(--main-font);
    }
  }

  .pic-container {
    perspective: 1500px;
    width: clamp(300px, 30vw, 600px);
    height: clamp(300px, 30vw, 600px);
    position: absolute;
    z-index: 1;
    right: clamp(0rem, 5vw, 10rem);

    .pic {
      transition: 0.3s;
      background-image: url(./assets/phoenix.jpeg);
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: 20%;
    }
  }
}
