.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: clamp(60px, 8vw, 100px);
    height: clamp(60px, 8vw, 100px);
    transition: all 0.3s ease-in-out;
    background-color: white;
    border-radius: 1rem;
    padding: clamp(0rem, 10%, 1rem);

    &:hover {
      transform: scale(1.1) translateY(-0.5rem) rotate(5deg);
    }
  }

  .icon-name {
    font-size: clamp(1rem, 2vw, 1.5rem);
    margin: 0.5rem 0 1rem 0;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
