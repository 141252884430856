$text-color: #a9a9a9;
$text-hover: #a2642b;

#Header {
  .navbar {
    background-color: rgba($color: #000, $alpha: 0.8) !important;
    border-bottom: solid 1px var(--secondary-color);
    font-family: var(--main-font);
    .container {
      .name {
        color: $text-color;
        font-size: clamp(1.2rem, 4vw, 1.5rem);
      }

      .tabs {
        a {
          color: $text-color;
          &:hover {
            transform: scale(1.1);
            text-decoration: underline;
            color: $text-hover;
          }
        }
      }

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(169, 169, 169, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
      }
    }
  }
}
