#Contact {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-color-1);
  color: var(--font-color-1);
  gap: 2rem;
  font-family: var(--main-font);

  .contact-container {
    position: absolute;

    .contact-title {
      font-size: clamp(1.5rem, 7vw, 5rem);
      font-weight: bold;
    }

    .contact-item {
      & > a {
        text-decoration: none;
        color: var(--font-color-1);
        font-size: clamp(1.2rem, 3vw, 2.5rem);
      }
    }
  }
}
