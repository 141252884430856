#Skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-color-1);
  color: var(--font-color-1);
  gap: 1rem;
  font-family: var(--main-font);

  .title {
    font-size: clamp(2rem, 6vw, 3.5rem);
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-thickness: 0.2rem;
  }

  .lang-frame,
  .dev-tools {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: clamp(1rem, 5vw, 1.5rem);

    .lang-frame-list,
    .dev-tools-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: clamp(1rem, 5vw, 2rem);
      font-size: 1rem;
    }
  }
}

.car .xxx {
  width: 10rem;
  height: 10rem;
  background-color: red;
  color: aqua;
}
