#About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
  background-color: var(--bg-color-2);
  color: var(--font-color-2);
  font-family: var(--main-font);
  line-height: 1.5;
  width: clamp(300px, 100%, 1200px);

  .title {
    font-size: clamp(2rem, 6vw, 3.5rem);
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-thickness: 0.2rem;
  }

  .content {
    font-size: clamp(0.85rem, 2.5vw, 1.5rem);
  }
}
