.footer {
  position: absolute;
  bottom: 1.5rem;
  right: 5vw;
  display: flex;
  gap: clamp(1rem, 3vw, 2rem);

  & > a {
    height: 50px;
    width: 50px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: scale(1.2);
    }
    & img {
      height: 50px;
      width: 50px;
      border-radius: 20%;
      filter: invert(1);
    }
  }
}
