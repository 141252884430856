.App {
  & > * {
    caret-color: transparent;

    background-clip: content-box;
  }

  .snap-container {
    & > div {
      padding: 0 5vw;
    }
  }
}
